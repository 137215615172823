import React from 'react';
import styled from 'styled-components';

import { ContactForm, ContactAddress } from 'components/contact';
import { BackgroundImage, SEO } from 'components/ui';
import { DESKTOP_GRID_COLUMN, DESKTOP_GRID_SPACING, TABLET_GRID_SIZE } from 'consts';
import { Colors, MediaQueries } from 'environment';
import { useQueryContext } from 'hooks';

import content from 'data/contact.json';
import seo from 'data/seo.json';

export default function Contact() {
    const {
        images: {
            contact: { hero: image }
        }
    } = useQueryContext();

    return (
        <BackgroundImage
            mobile={image.mobile}
            tablet={image.tablet}
            desktop={image.desktop}
            alt={content.alt}
        >
            <Container>
                <SEO
                    title={seo.contactTitle}
                    description={seo.contactDescription}
                    ogTitle={seo.contactOgTitle}
                    ogDescription={seo.contactOgDescription}
                    ogImage={seo.contactOgImage}
                />
                <TextContainer>
                    <Title>{content.title}</Title>
                    <Description>{content.description}</Description>
                </TextContainer>
                <Content>
                    <ContactForm />
                    <AddressContent>
                        <ContactAddress />
                    </AddressContent>
                </Content>
            </Container>
        </BackgroundImage>
    );
}

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 60rem;
    justify-content: center;
    text-align: center;
    color: ${Colors.violet};
    padding-top: 12rem;
    padding-bottom: 8rem;

    @media ${MediaQueries.desktop} {
        text-align: start;
    }

    @media ${MediaQueries.phone} {
        padding: 6rem 2rem;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 12rem;

    @media ${MediaQueries.desktop} {
        flex-direction: row;
    }

    @media ${MediaQueries.phone} {
        padding-bottom: 6rem;
    }
`;

export const AddressContent = styled.div`
    display: flex;

    @media ${MediaQueries.tablet} {
        margin-top: 8rem;
        justify-content: center;
    }

    @media ${MediaQueries.phone} {
        margin-top: 6rem;
    }
`;

export const Title = styled.h1`
    @media ${MediaQueries.phone} {
        font-size: 3.6rem;
        line-height: 5rem;
        font-weight: 700;
    }
`;

export const Description = styled.h4`
    font-weight: normal;
    margin-top: 2rem;
`;

export const Container = styled.div`
    width: 100%;
    justify-content: center;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 12 + DESKTOP_GRID_SPACING * 11}rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE}rem;
    }

    @media ${MediaQueries.phone} {
        padding: 0 2rem;
    }
`;
